'use client';
import { FIELD_NAMES } from '@/ducks/travelParty/constants';

import constants from '../constants';
import observer from '../observer';
import * as eventTypes from './types';

observer.trackingEventsObserver.subscribe({
  handler: ({ dispatch, packages }) => {
    dispatch(eventTypes.cordialTags.searchCordial({ packages }));
  },
  name: observer.TRACKING_EVENTS_FOR_OBSERVER.CHOOSE_SAILING_PAGE_LOADED,
});

observer.trackingEventsObserver.subscribe({
  handler: ({ dispatch, labels, list, sailings }) => {
    dispatch(
      eventTypes.ECommercePromotions.measuringProductImpressions({
        labels,
        list,
        sailings,
      }),
    );
  },
  name: observer.TRACKING_EVENTS_FOR_OBSERVER.SAILING_PACK_APPEARED,
});

observer.trackingEventsObserver.subscribe({
  handler: ({ cards, dispatch }) => {
    dispatch(eventTypes.ECommercePromotions.measuringPromotionImpressions(cards));
  },
  name: observer.TRACKING_EVENTS_FOR_OBSERVER.CHOOSE_CABINS_APPEARED,
});

observer.trackingEventsObserver.subscribe({
  handler: ({ cabinCategory, dispatch, metaCabinCategories, sailing }) => {
    dispatch(eventTypes.cordialTags.viewContentCordial({ cabinCategory, metaCabinCategories, sailing }));
  },
  name: observer.TRACKING_EVENTS_FOR_OBSERVER.CHOOSE_CABIN_CATEGORY_APPEARED,
});

observer.trackingEventsObserver.subscribe({
  handler: ({ dispatch, sailing, subMetaCategory }) => {
    dispatch(
      eventTypes.ECommercePromotions.measuringDetailsImpressions({
        list: constants.LISTS.YOUR_VOYAGE,
        sailing,
        subMetaCategory,
      })(),
    );
  },
  name: observer.TRACKING_EVENTS_FOR_OBSERVER.CHOOSE_CABIN_META_CATEGORY_APPEARED,
});

observer.trackingEventsObserver.subscribe({
  handler: ({ calculateInvoice, dispatch, sailing }) => {
    dispatch(eventTypes.cordialTags.addToCardContentCordial({ calculateInvoice, sailing }));
  },
  name: observer.TRACKING_EVENTS_FOR_OBSERVER.CHECKOUT_SUMMARY_PAGE_LOADED,
});

observer.trackingEventsObserver.subscribe({
  handler: ({ dispatch, sailing, subMeta }) => {
    dispatch(
      eventTypes.ECommercePromotions.measuringCheckoutStep({
        action: constants.MEASURES.CHECKOUT_STEP_1,
        list: constants.LISTS.YOUR_VOYAGE,
        sailing,
        step: 1,
        subMetas: [subMeta],
      }),
    );
  },
  name: observer.TRACKING_EVENTS_FOR_OBSERVER.CHECKOUT_SUMMARY,
});

observer.trackingEventsObserver.subscribe({
  handler: ({ calculateInvoice, dispatch, sailing }) => {
    dispatch(eventTypes.cordialTags.initiateCheckoutCordial({ calculateInvoice, sailing }));
  },
  name: observer.TRACKING_EVENTS_FOR_OBSERVER.INITIATE_CHECKOUT,
});

observer.trackingEventsObserver.subscribe({
  handler: ({ dispatch, sailing, subMeta }) => {
    dispatch(
      eventTypes.ECommercePromotions.measuringCheckoutStep({
        action: constants.MEASURES.CHECKOUT_STEP_2,
        list: constants.LISTS.YOUR_VOYAGE,
        sailing,
        step: 2,
        subMetas: [subMeta],
      }),
    );
  },
  name: observer.TRACKING_EVENTS_FOR_OBSERVER.CHECKOUT_SAILOR_DETAILS,
});

observer.trackingEventsObserver.subscribe({
  handler: ({ primarySailor, subscriptionId, ...rest }) => {
    eventTypes.cordialTags.completeCheckoutCordial({ primarySailor, subscriptionId, ...rest });
  },
  name: observer.TRACKING_EVENTS_FOR_OBSERVER.CHECKOUT_SUBSCRIBED_FOR_NEWS,
});

observer.trackingEventsObserver.subscribe({
  handler: ({ calculateInvoice, dispatch, primarySailor, sailing }) => {
    dispatch(eventTypes.cordialTags.addPaymentInfoCordial({ calculateInvoice, primarySailor, sailing }));
  },
  name: observer.TRACKING_EVENTS_FOR_OBSERVER.PAYMENT_PAGE_LOADED,
});

observer.trackingEventsObserver.subscribe({
  handler: (props) => {
    eventTypes.common.trackIconClick({
      labels: [constants.EVENT_LABELS.INFO_ICON],
      ...props,
    });
  },
  name: observer.TRACKING_EVENTS_FOR_OBSERVER.INFO_CLICK,
});

observer.trackingEventsObserver.subscribe({
  handler: ({ key, value }) => {
    if (key === FIELD_NAMES.IS_OPT_IN_FOR_EMAIL) {
      eventTypes.summary.trackMobileOptInToggle(value);
    }
  },
  name: observer.TRACKING_EVENTS_FOR_OBSERVER.SAILOR_FORM_FIELD_CHANGED,
});

observer.trackingEventsObserver.subscribe({
  handler: ({ key, value }) => {
    if (key === FIELD_NAMES.IS_OPT_IN_FOR_EMAIL_RADIO) {
      eventTypes.summary.trackEmailOptInRadioToggle(value);
    }
  },
  name: observer.TRACKING_EVENTS_FOR_OBSERVER.SAILOR_FORM_FIELD_CHANGED,
});
