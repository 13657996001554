import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/env-provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/use-env-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/src/components/ChooseVoyage/ViewMoreDates/PricedSailingCard/PricedSailingCard.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/UIResource/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/layouts/Base.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/tagmanager/event/watchers.js");
